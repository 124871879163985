export const ticketTypeIds: string[] = [
  'CHANGE_REQUEST',
  'RETURN_REQUEST'
];

export enum CustomerSearchType {
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  EMAIL = 'EMAIL',
  ORDER_ID = 'ORDER_ID',
  SALE_NOTE_NUMBER = 'SALE_NOTE_NUMBER',
  TICKET_NUMBER = 'TICKET_NUMBER'
}

export enum Role {
  ADMIN = 'ADMIN',
  CORPORATE_ADMIN = 'CORPORATE_ADMIN',
  CORPORATE_SUPERVISOR = 'CORPORATE_SUPERVISOR',
  THIRDPARTY_SUPERVISOR = 'THIRDPARTY_SUPERVISOR',
  CORPORATE_AGENT = 'CORPORATE_AGENT',
  THIRDPARTY_AGENT = 'THIRDPARTY_AGENT',
  THIRDPARTY_ADMIN = 'THIRDPARTY_ADMIN',
  SHOPSTAR_AGENT = 'SHOPSTAR_AGENT',
  SHOPSTAR_SUPERVISOR = 'SHOPSTAR_SUPERVISOR'
}

export enum RuleGroup {
  ECOMMERCE_ORDERS = 'ECOMMERCE_ORDERS'
}

export enum SourceChannel {
  REX = 'REX', 
  MY_ORDERS = 'MY_ORDERS'
}

export const regexTypeData : Map<string, RegExp> = new Map([
  ['email', new RegExp(/^[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]@[a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z]{2,3}?$/)],
  ['id', new RegExp(/^\d{8,12}?$/)],//dni|pasaporte|ruc(11)-carnet de extranjeria(12)
  ['incidence', new RegExp(/^(PVEA|VIV|RPLAZA|HPSA|TPSA|AGORA|MKR|AGORA_SHOP|JOKR|SHPSTAR)-(INC|LDR|CD|ST)-\d{8,12}-[0-9]+$/)],
  ['order', new RegExp(/^((\d{13}-\d{2})|(\d{15})|(v\d+viv-\d{2})|(v\d+plzv-\d{2}|(v\d+pvqa-\d{2})|(v\d+vivqa-\d{2})))?$/)],
  ['sale-note', new RegExp(/^\d{13}?$/)]
]);

export const searchTypeMap: Map<string, CustomerSearchType> = new Map([
  ['email', CustomerSearchType.EMAIL],
  ['id', CustomerSearchType.IDENTITY_DOCUMENT],
  ['incidence', CustomerSearchType.TICKET_NUMBER],
  ['order', CustomerSearchType.ORDER_ID],   
  ['sale-note', CustomerSearchType.SALE_NOTE_NUMBER],
]);

export const searchRoleMap: Map<string, string> = new Map([
  [ Role.ADMIN, 'Administrador Customer Care' ],
  [ Role.CORPORATE_ADMIN, 'Administrador' ],
  [ Role.THIRDPARTY_ADMIN, 'Administrador externo' ],
  [ Role.CORPORATE_SUPERVISOR, 'Supervisor' ],
  [ Role.THIRDPARTY_SUPERVISOR, 'Supervisor externo' ],
  [ Role.SHOPSTAR_SUPERVISOR, 'Supervisor Shopstar' ],
  [ Role.CORPORATE_AGENT, 'Gestor' ],
  [ Role.THIRDPARTY_AGENT, 'Gestor externo' ],
  [ Role.SHOPSTAR_AGENT, 'Gestor Shopstar' ]
]);

export enum CatalogType {
  TENANTS = "TENANTS",
  TICKET_TYPE = "TICKET_TYPE",
  REASONS = "REASONS",
  RESPONSIBILITY = "RESPONSIBILITY",
  DELIVERY_STORE = "DELIVERY_STORE",
  STORES = 'STORES',
  STORE_TYPES = 'STORE_TYPES',
  STATES = 'STATES',
  UBIGEO = 'UBIGEO',
  ACTION = 'ACTION'
}

export const requestActionResponseMessage: any[] = [
  { requestActionId: 1,  responseMessage: 'Se ha añadido un <strong>comentario</strong> exitosamente.' },
  { requestActionId: 2,  responseMessage: 'Se ha añadido un <strong>recontacto</strong> exitosamente.' },
  { requestActionId: 3,  responseMessage: 'Se ha actualizado el <strong>cambio de estado</strong> exitosamente.' },
  { requestActionId: 4,  responseMessage: 'Se ha actualizado el <strong>cambio de priorización</strong> exitosamente.' },
  { requestActionId: 5,  responseMessage: 'La solicitud ha sido <strong>reasignada</strong> exitosamente.' },
  { requestActionId: 7,  responseMessage: 'La solicitud se ha <strong>aprobado</strong> exitosamente.' },
];

export enum ManagementMode {
  HOME_PICKUP = "HOME_PICKUP",
  DELIVERY_IN_STORE = "DELIVERY_IN_STORE"
}

export enum RequestLabels {
  OPEN_PACKAGE = "Empaque abierto",
  CLOSED_PACKAGE = "Empaque cerrado"
}

export enum RequestStatus {
  PENDING_PHOTOS = 1,
  PENDING = 2,
  IN_REVIEW = 3,
  FINISHED = 4,
  CANCELLED = 5
}

export const allowed_tenants: string[] = [
  'HPSA',
  'TPSA',
  'PVEA',
  'RPLAZA',
  'SELLER_CENTER'
];

const tenantNames = new Map([
  ['HPSA', 'Promart'],
  ['TPSA', 'Oechsle'],
  ['PVEA', 'plazaVea'],
  ['AGORA', 'Agora.pe'],
  ['RPLAZA', 'Real Plaza'],
  ['AGORA_SHOP', 'Agora Shop'],
  ['SHPSTAR', 'Shopstar'],
  ['MKR', 'Makro'],
  ['JOKR', 'Jokr'],
])

const sellerNames = new Map([
  ['Promart', 'HPSA'],
  ['Oechsle', 'TPSA'],
  ['plazaVea', 'PVEA'],
  ['Vivanda', 'VIV'],
  ['Makro', 'MKR'],
  ['Jokr', 'JOKR']
])

const slaStatusNames = new Map([
  ['ON_TIME', 'A tiempo'],
  ['ABOUT_EXPIRE', 'Por vencer'],
  ['EXPIRED', 'Vencido']
])

const requestStatus = new Map([
  [1, 'PENDING_PHOTOS'],
  [2, 'PENDING'],
  [3, 'IN_REVIEW'],
  [4, 'COMPLETED'],
  [5, 'CANCELLED'],
])

export const reasonResponsibleMap: Map<string, string> = new Map([
  [ 'COMPANY', 'Empresa' ],
  [ 'CUSTOMER', 'Cliente' ]
]);

export const prioritiesMap: Map<string, string> = new Map([
  [ 'LOW', 'Media' ],
  [ 'HIGH', 'Alta' ],
  [ 'CRITICAL', 'Muy alta' ]
]);

export const approveRejectMap: Map<string, string> = new Map([
  [ 'APPROVE', 'Aprobado' ],
  [ 'REJECT', 'Rechazado' ]
]);

export const managementModeMap: Map<string, string> = new Map([
  [ ManagementMode.HOME_PICKUP, 'Recolección a domicilio' ],
  [ ManagementMode.DELIVERY_IN_STORE, 'Entrega en tienda' ]
]);

export const responsibilities: any[] = [
  { responsibilityId: 'COMPANY',  responsibilityName: 'Empresa' },
  { responsibilityId: 'CUSTOMER',  responsibilityName: 'Cliente' }
];

export const packageStatuses: any[] = [
  { packageStatusId: 'OPEN_PACKAGE',  packageStatusName: RequestLabels.OPEN_PACKAGE },
  { packageStatusId: 'CLOSED_PACKAGE',  packageStatusName: RequestLabels.CLOSED_PACKAGE }
];

export const slaStatuses: any[] = [
  { slaStatusId: 'ON_TIME',  slaStatusName: 'A tiempo' },
  { slaStatusId: 'ABOUT_EXPIRE',  slaStatusName: 'Por vencer' },
  { slaStatusId: 'EXPIRED',  slaStatusName: 'Vencido' }
];

export const dateTypes: any[] = [
  { dateTypeId: 'REASSIGNED_AT',  dateTypeName: 'Fecha de asignación' },
  { dateTypeId: 'CREATED_AT',  dateTypeName: 'Fecha de creación' }
];

export const priorities: any[] = [
  { priorityId: 'LOW',  priorityName: 'Media', priorityIconName: 'horizontal_rule' },
  { priorityId: 'HIGH',  priorityName: 'Alta', priorityIconName: 'keyboard_arrow_up'},
  { priorityId: 'CRITICAL',  priorityName: 'Muy alta', priorityIconName: 'keyboard_double_arrow_up' }
];

export const recontacts: any[] = [
  { recontactId: '1',  recontactName: 'Sin recontactos' },
  { recontactId: '2',  recontactName: 'De 1 a 3 recontactos' },
  { recontactId: '3',  recontactName: 'De 4 a 6 recontactos' },
  { recontactId: '4',  recontactName: 'De 7 a 9 recontactos' },
  { recontactId: '5',  recontactName: 'De 10 a más recontactos' },
];

export const tags: any[] = [
  { tagId: '1',  tagName: 'Producto cerrado' },
  { tagId: '2',  tagName: 'Producto abierto' },
  { tagId: '3',  tagName: 'Producto vencido' },
  { tagId: '4',  tagName: 'Calidad de producto' }
];

export const commons = {
  ticketTypeIds: ticketTypeIds,
  regexTypeData: regexTypeData,
  searchTypeMap: searchTypeMap,
  searchRoleMap: searchRoleMap,
  reasonResponsibleMap: reasonResponsibleMap,
  tenantNames,
  sellerNames,
  slaStatusNames,
  requestStatus,
  responsibilities,
  packageStatuses,
  slaStatuses,
  dateTypes,
  priorities,
  recontacts,
  tags
}
